import React from "react"
import { Link } from "gatsby"

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
  <SEO title="404: Not found — Stapleton Designs" />
  <div className="content top">
    <div className="container">
      <p>Stapleton Designs</p>
    </div>
  </div>
  <div className="content bottom" style={{ maxWidth: 540 }}>
    <div className="container">
      <p><span className="font-weight-600">Not Found</span> &#151; You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/" className="btn no-left-right-margin no-bottom-margin">Back Home</Link>
    </div>
  </div>
  </Layout>
)

export default NotFoundPage
